header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 20px 10px;
}

nav {
  display: flex;
  justify-content: space-between;
}

ul {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 10px;
}

li {
  list-style: none;
  cursor: pointer;
}

li a {
  color: #099ddd;
  border-right: solid 2px black;
  padding: 0 10px;
}
