* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrapper {
  padding-left: 10px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.output {
  width: 400px;
  height: 30px;
  margin-top: 10px;
  background-color: #858493;
  color: white;
  text-align: right;
  padding-right: 5px;
}

.buttons-row {
  display: flex;
}

.btn {
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #b8b8bb;
}

.btn:hover {
  cursor: pointer;
  border: solid 2px #858493;
}

.gray {
  background-color: #e2e2e2;
}

.orange {
  background-color: #f2933d;
}

.double {
  width: 200px;
}
