.homewrapper {
  padding-left: 10px;
}

h1 {
  padding-bottom: 20px;
}

p {
  margin: 2%;
}
